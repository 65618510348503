import { ElementRef, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

import { Button } from "@app/components/button";
import checkedSrc from "@app/features/onboarding/radio-group-field/checked.svg";
import { Dialog } from "@app/components/dialog";
import { Search } from "@app/components/search";
import chevronUpSrc from "./chevron-up.svg";
import clsx from "clsx";

export interface Option {
	name: string;
	id: number | string;
}

export const SingleSelectDialog = ({
	value,
	options,
	onChange,
	placeholder,
	error,
	searchText = "Search",
	title,
	className,
}: {
	onChange: (value: Option) => void;
	options: Array<Option>;
	value?: Option;
	placeholder?: string;
	searchText?: string;
	error?: boolean;
	title?: string;
	className?: string;
}) => {
	const triggerRef = useRef<ElementRef<"button">>(null);
	const [search, setSearch] = useState("");
	const modalListRef = useRef<ElementRef<"div">>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState<Option>();
	const [isScrollable, setIsScrollable] = useState(false);

	const filteredOptions = options.filter((option) => {
		return option.name.toLowerCase().includes(search.toLowerCase());
	});

	useEffect(() => {
		if (modalListRef.current && options.length > 0 && isOpen) {
			const { scrollHeight, clientHeight } = modalListRef.current;
			setIsScrollable(scrollHeight > clientHeight);
		}
	}, [isOpen, options]);

	useEffect(() => {
		if (isOpen && value) {
			setSelected(value);
		}
	}, [isOpen, value]);

	const isBottomsheet = options.length <= 5;

	return (
		<>
			<button
				className={clsx(styles.trigger, className)}
				type="button"
				data-error={error}
				data-open={isOpen}
				ref={triggerRef}
				onClick={() => setIsOpen(true)}
			>
				{value ? (
					value.name
				) : (
					<span className={styles.placeholder}>{placeholder}</span>
				)}
				<img
					data-open={isOpen}
					className={styles.chevron}
					src={chevronUpSrc}
					alt=""
					width={20}
					height={20}
				/>
			</button>
			<Dialog
				fullscreen={!isBottomsheet}
				bottomsheet={isBottomsheet}
				isOpen={isOpen}
				zIndex={103}
				onClose={() => setIsOpen(false)}
				title={title}
				actions={
					<Button
						onClick={() => {
							setIsOpen(false);
						}}
					>
						Cancel
					</Button>
				}
			>
				{isScrollable && (
					<Search
						value={search}
						onChange={(event) => setSearch(event.target.value)}
						placeholder={searchText}
					/>
				)}
				<div className={styles.modalList}>
					{filteredOptions.map((option) => {
						const isChecked = selected?.id === option.id;
						return (
							<button
								type="button"
								className={styles.modalItem}
								aria-current={isChecked}
								aria-label={option.name}
								key={option.id}
								onClick={() => {
									setSelected(option);
									onChange(option);
									setIsOpen(false);
								}}
							>
								<p>{option.name}</p>
								{isChecked ? (
									<img src={checkedSrc} alt="" width={16} height={16} />
								) : (
									<div className={styles.unchecked} />
								)}
							</button>
						);
					})}
				</div>
			</Dialog>
		</>
	);
};
