import { isLocalOrTest } from "@app/config/env";

export const isPrimaryShareholdersEnabled = false;
export const isChangeEmailAddressEnabled = false;

export const isMultipleFileUploadEnabled = true;
export const isArbInactiveClientEnabled = true;

export const isOTPAuthEnabled = true;
export const isAvailableBalanceEnabled = isLocalOrTest;
export const isMobileTrayMenuEnabled = isLocalOrTest;
