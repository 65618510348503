import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Dropdown as BaseDropdown } from "@app/components/controls";
import { Typography } from "@app/components/typography";

import { FieldError } from "@app/components/field-error";
import styles from "../index.module.css";
import { showMappedReasons } from "../show-mapped-reasons";
import type { DropDownPartialProps } from "../types";

export const DropDown = (props: DropDownPartialProps) => {
	return (
		<Controller
			name={props.name}
			control={props.formControl}
			rules={{
				required: props.required ? "This field is required" : false,
				validate: (x) =>
					props.onCustomValidationRule?.(x) || props.customErrorMessage,
			}}
			render={({ field, fieldState: { error } }) => {
				const hasError =
					!!error ||
					showMappedReasons(
						props.mappedName ?? field.name,
						props.mappedReasons,
					) !== false;

				return (
					<div
						className={`${props.fieldRowClassName || ""} ${styles.inputRow}`}
					>
						{props.showLabel ? (
							<Typography theme="textSm" className={`${styles.formLabel}`}>
								{props.title +
									(props.required && !props.hideAsterisk ? "*" : "")}
							</Typography>
						) : (
							<></>
						)}
						<BaseDropdown
							appendToParent={props.appendToParent}
							className={props.className}
							panelClassName={twMerge(
								"form-dropdown",
								props.filter && "filterable",
								props.panelClassName ?? "",
							)}
							disabled={props.disabled}
							options={props.options || []}
							{...field}
							iconSize={props.iconSize}
							iconColour={props.iconColour}
							filter={props.filter}
							filterPlaceholder={props.filterPlaceholder}
							onChange={(value) => {
								if (props.onChange) props.onChange(value);
								field.onChange(value);
							}}
							optionLabel={props.optionLabel || undefined}
							placeholder={props.placeholder}
							error={hasError}
						/>
						{error && <FieldError>{error.message}</FieldError>}
						{!error && showMappedReasons(field.name, props.mappedReasons)}
					</div>
				);
			}}
		/>
	);
};
